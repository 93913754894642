<template>
  <div class="TipsNews">
    <top-bar title="公共事项" hasBack></top-bar>
    <div class="content">
      <img src="../../assets/imgs/gonggao0425.png" class="gonggao" alt="">
      <div class="TipsNews-text">
        <div class="TipsNews-text-item">
          2021年4月1日公开事项
        </div>

      </div>

      <van-icon name="arrow" class="TipsNews-back" />
    </div>
    <div class="content">
      <img src="../../assets/imgs/gonggao0425.png" class="gonggao" alt="">
      <div class="TipsNews-text">
        <div class="TipsNews-text-item">
          2021年4月1日公开事项
        </div>

      </div>

      <van-icon name="arrow" class="TipsNews-back" />
    </div>
  </div>
</template>

<script>
  import TopBar from '@/components/TopBar';
  import { GoodsMixin } from '@/mixins/goodsMixin';
  import ajax from '@/api';
  export default {
    name: 'PublicItem',
    mixins: [ GoodsMixin ],
    inject: [ 'reload' ],
    components: { TopBar },
    data() {
      return {

      }
    },
    watch: {

    },
    created() {

    },
    methods: {

    }
  }

</script>

<style lang="scss" scoped>
  @import "./style.scss";
</style>